

.main-color {
  color: var(--calc-main-color);
}

.App {
  text-align: center;
  color: var(--calc-text-color-light)
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.calc-desc,
.disclaimer {
  color: var(--calc-text-color-light)
}

.small {
  font-size: .7em;
  margin-top: .5em;
}

.App-header {
  /* background-color: var(--calc-text-color-dark); */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.wrapper {
  width: 100%;
  /* height: 400px; */
  background-color: var(--calc-text-color-dark);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2em;
  /* border-radius: 20px; */
  transition: all 1s;
}

.nav-arrow {
  border: 1px solid var(--calc-text-color-light);
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 1em;
}

.nav-arrow.active {
  border: none;
  font-size: 1.4em;
  background: var(--calc-sec-color);
  border-radius: 10px;
  color: var(--calc-text-color-dark);
  cursor: pointer;
  transition: all 1s;
}

.nav-arrow:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.nav-arrow.active::before {
  content: "\2713 ";

}

.question {
  margin: 2em 0;
  background: rgba(255, 255, 255, .1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: ease-in 1s;
}

.question-headline {
  color: var(--calc-main-color);
}

.answers-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2em 0 0 0;
  flex-wrap: unset;
}

.answer-btn {
  height: 50px;
  color: var(--calc-text-color-light);
  padding: 7px 12px;
  margin: .5em;
  border-radius: 7px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s;
}

.submit_btn {
  height: 50px;
  color: var(--calc-text-color-light);
  padding: 7px 12px;
  margin: .5em;
  border-radius: 7px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s;
}

.submit_btn.active {
  background: var(--calc-main-color);
  color: var(--calc-text-color-dark);
  /* font-weight: bold; */
  /* transform: scale(1.1); */
}

.answer-btn.active {
  background: var(--calc-main-color);
  color: var(--calc-text-color-dark);
  /* font-weight: bold; */
  /* transform: scale(1.1); */
}

.answer-btn.active span {
  display: none;
}

.answer-btn:hover {
  /* transform: scale(1.1); */
  color: var(--calc-text-color-dark);
  background: var(--calc-main-color);
}

.answer-btn.active:hover {
  background: var(--calc-sec-color);
  color: var(--calc-text-color-dark);
  /* transform: scale(1.1); */

}

/* put root styles here */

/* // Small devices (landscape phones, 576px and up) */
/* iphone 5/SE  PORTRAIT */
@media (min-width: 320px) { 
  .answers-wrapper {
    flex-wrap: wrap;
  }

}
/* iphone 6 - X PORTRAIT */
@media (min-width: 375px) { 
  .answers-wrapper {
    flex-wrap: wrap;
  }
}
/* iphone 8 Plus PORTRAIT */
@media (min-width: 414px) { 
  .answers-wrapper {
    flex-wrap: wrap;
  }
}
/* iphone 5/SE  LANDSCAPE */
@media (min-width: 568px) { 
  .answers-wrapper {
    flex-wrap: wrap;
  }
}
/* iphone 6-8  LANDSCAPE */
@media (min-width: 667px) { 
  .answers-wrapper {
    flex-wrap: wrap;
  }
}

@media (min-width: 667px) and (orientation : portrait) { 
  .answers-wrapper {
    flex-wrap: wrap;
  }
}

/* iphone 6-8 Plus LANDSCAPE */
@media (min-width: 736px) { 
  .answers-wrapper {
    flex-wrap: wrap;
  }
}
/* iPad Air, iPad Mini, iPad Pro 9″ PORTRAIT */
@media (min-width: 768px) { 
  .answers-wrapper {
    flex-wrap: wrap;
  }
}

/* iPad Pro 10″ PORTRAIT  */
@media (min-width: 812px) and (orientation : portrait){
  .answers-wrapper {
    flex-wrap: wrap;
  }
}

/* iphone X LANDSCAPE */
@media (min-width: 812px) and (orientation : landscape) { 
  .answers-wrapper {
    flex-wrap: unset;
  }
}

/* iPad Air, iPad Mini, iPad Pro 9″ LANDSCAPE  */
@media (min-width: 1024px) {
  .answers-wrapper {
    flex-wrap: unset;
  }
}

/* iPad Pro 12″ PORTRAIT  */
@media (min-width: 1024px) and (orientation : portrait) {
  .answers-wrapper {
    flex-wrap: unset;
  }
}

/* iPad Pro 10″ LANDSCAPE  */
@media (min-width: 1112px) {
  .answers-wrapper {
    flex-wrap: wrap;
  }
}

/* HD laptops (768p)  */
@media (min-width: 1366px) {
  .answers-wrapper {
    flex-wrap: unset;
  }
}

/* iPad Pro 12″ LANDSCAPE  */
@media (min-width: 1366px) and (orientation : portrait) {
  .answers-wrapper {
    flex-wrap: unset;
  }
}

/* 13″ MacBook Pro (2x scaling)  */
@media (min-width: 1440px) {
  .answers-wrapper {
    flex-wrap: unset;
  }
}

/* 13″ MacBook Pro (1.5x scaling)  */
@media (min-width: 1680px) {
  .answers-wrapper {
    flex-wrap: unset;
  }
}

/* 1080p displays  */
@media (min-width: 1920px) {
  .answers-wrapper {
    flex-wrap: unset;
  }
}

